import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import Dinamiza from 'components/colorDinamiza'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import Image from 'components/image'
import style from 'scss/pages/projects/vega.module.scss'
import styleSpecific from 'scss/pages/projects/petra.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'
import VideoIllustration from "blocks/case/videoIllustration";
import UxCroAnimation from "images/projects/petra/ux-cro.json"

class Petra extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/petra/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#ffca30",
      logoUrl: imagePath+'logo_petra.svg',
      image: imagePath+'bg_petra.jpg',
      title: "Un ecommerce nel settore Food",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","SOFTWARE ARCHITECTURE","SYSTEM INTEGRATION","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/petra1.jpg','/img/screenshot/petra2.jpg','/img/screenshot/petra3.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Petra" description={'Partendo dalla digital strategy, sviluppiamo progetti digitali come ecommerce, marketplace, piattaforme web applicazioni mobile per il settore food.'} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Un ecommerce nel settore Food</h2>
            <p>Petra, marchio sviluppato da Molino Quaglia è un marchio ormai affermato nel settore food per la produzione di farine e prodotti derivati di alta qualità. Il progetto verteva nella creazione di uno store dedicato al cliente privato, ma che fosse di supporto anche alla rete vendita dell'azienda.</p>
          </SectionHalfIntroduction>

          <SectionTextImageContainer>
            <ParagraphLine>
              <h2 data-title>Creazione di UX e UI per massimizzazione del CRO</h2>
              <p>La ridefinizione della Brand identity richiedeva un'immagine digitale moderna e pulita e una semplificazione del processo di acquisto e della massimizzazione del CRO (Conversion Rate Optimization)</p>
            </ParagraphLine>
            <div style={{ textAlign: 'center' }}>
              <VideoIllustration src={UxCroAnimation} style={{width:'300px',height:'600px',margin:'auto'}} />
            </div>
          </SectionTextImageContainer>

          <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
            <img src={imagePath+'ricette_petra.png'} className={`${style.page__firstImage} ${styleSpecific.secondImage_image}`}  />
            <div className={styleSpecific.secondImage_text}>
              <h2 data-title>Le Ricette Petra</h2>
              <p>Come supporto per l'ampia community di amanti dei prodotti Petra è stato creato un sistema aperto per la condivisione delle ricette basate sulle farine Petra. Il sistema è integrato con il sistema di e-commerce.</p>
            </div>
          </SectionTextImageFullWidth>

          <SectionDark imageSrc={imagePath+'bg_petra.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <img src={imagePath+'mockup_petra.png'} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>E-commerce multicanale</h2>
                <p className={`has-color-primary-negative`}>Per supportare la possibilità di acquisto da diversi Touch Point l'e-commerce è stato arricchito di diverse funzionalità studiate per garantire un processo di acquisto a partire da piattaforme della rete Petra.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
              <div className={style.page__secondImage}>
                <img src={imagePath+'automazione_petra.svg'} />
              </div>
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Elevata automazione del processo di evasione dell'ordine</h2>
                <p className={`has-color-primary-negative`}>Il sistema è perfettamente integrato con il CRM, con l'ERP, con il sistema di gestione del magazzino e con i principali player della logistica attraverso canali di comunicazione via API.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Creazione di una shopping experience inedita e omnicanale, tramite lo sviluppo di una interfaccia intuitiva ed emozionale, integrata con i sistemi di gestione e con il CRM. Aumento dell’engagement e coinvolgimento di nuove audience.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'aiti'} nextProject={'navigando'} nextProjectColor={'#fff'} />

        </Layout>
      </div>
    )

  }
}

export default Petra
